import logo from "./logo.svg";
import "./App.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { useState, useEffect } from "react";
import videophoto from "./photo.mp4";
import bgvideo from "./background.mp4";
import vodalogo from "./vodalogo.png";
function App() {
  const [todos, setTodos] = useState([]);

  function shuffleArray(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  const fetchPost = async () => {
    await getDocs(collection(db, "vodacom")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTodos(newData);

      console.log(todos, newData);
    });
  };
  useEffect(() => {
    fetchPost();
    const interval = setInterval(() => {
      fetchPost();
    }, 100); // Fetch every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on component
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTodos((data) => shuffleArray(data));
    }, 100); // Fetch every 2 seconds

    // return () => clearInterval(interval); // Cleanup interval on component
  }, []);
  return (
    <div className="App">
      <header className="App-header"></header>
      <video
        src={bgvideo}
        muted
        autoPlay
        loop
        style={{ opacity: "0.4", position: "absolute" }}
      ></video>
      <div className="gallery">
        {todos.map((photo, index) => {
          return (
            <div key={index} className="photo">
              <nav>
                <nav
                  style={{
                    width: "10px",
                  }}
                >
                  <img src={vodalogo} alt="" />
                </nav>
              </nav>
              <video
                src={videophoto}
                muted
                autoPlay
                loop
                style={{
                  position: "absolute",
                  transform: "scale(1.2",
                  opacity: "0.4",
                }}
              ></video>
              <div>
                <img src={photo.img} alt={`Photo ${index + 1}`} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default App;
